import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { BiArrowBack } from "react-icons/bi";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import BuyModal from "./buyModal";
import BuyPostRow from "./buyPostRow";
import currencyData from "../../Currency/currency.json";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts } from "../../Redux/Action/postAction";
import "./buyPage.css";

export default function BuyPage() {
  const loginUser = useSelector((state) => state.user.user.data);
  const posts = useSelector((state) => state.posts.posts);
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [pair, setPair] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [currencyFrom, setCurrencyFrom] = useState(null);
  const [currencyTo, setCurrencyTo] = useState(null);
  const setupModelRef = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPosts(loginUser ? loginUser.id : 0));
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchPosts(loginUser ? loginUser.id : 0));
    }, 15000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    applyFilter();
  }, [posts, currencyFrom, currencyTo]);

  const applyFilter = () => {
    const filtered = posts.filter((post) => {
      return (
        (!currencyFrom || post.primary_currency === currencyFrom.code) &&
        (!currencyTo || post.secondary_currency === currencyTo.code)
      );
    });
    setFilteredPosts(filtered);
  };

  const backMain = () => {
    navigate("/");
  };

  const buyFromPost = (data) => {
    // console.log("buy data: ", data);
    setPair(data);
    setModalVisible(true);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img className="flagImg" src={option.flag} alt={option.code} />
        <div>{option.code}</div>
      </div>
    );
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.code} src={option.flag} className="flagImg" />
          <div>{option.code}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <div className="setupForexWrap">
      <ConfirmDialog />
      <div className="button-group">
        <Button className="backBtn" onClick={() => backMain()} text>
          <BiArrowBack />{" "}
        </Button>
        <h1>All Live Posts</h1>
        <div>
          <>
            <Button
              className="refreshBtn"
              icon="pi pi-refresh"
              onClick={() => dispatch(fetchPosts(loginUser.id))}
            ></Button>
          </>
        </div>
      </div>
      <div className="filter-group">
        <Dropdown
          value={currencyTo}
          options={currencyData}
          onChange={(e) => setCurrencyTo(e.value)}
          optionLabel="code"
          placeholder="Select To Currency"
          className="filter-dropdown"
          filter
          filterBy="code"
          showClear
          valueTemplate={selectedCountryTemplate}
          itemTemplate={countryOptionTemplate}
        />
        <Dropdown
          value={currencyFrom}
          options={currencyData}
          onChange={(e) => setCurrencyFrom(e.value)}
          optionLabel="code"
          placeholder="Select From Currency"
          className="filter-dropdown"
          filter
          filterBy="code"
          showClear
          valueTemplate={selectedCountryTemplate}
          itemTemplate={countryOptionTemplate}
        />
      </div>
      <div className="custom-table-container">
        <div className="custom-table-header">
          <div>Buying with Selling</div>
          <div>Exchange Rate</div>
          <div>Amount</div>
        </div>
        {filteredPosts.map((post, index) => (
          <BuyPostRow
            key={post.primary_currency + post.secondary_currency + index}
            post={post}
            buyFromPost={buyFromPost}
          />
        ))}
      </div>
      {loginUser && (
        <BuyModal
          ref={setupModelRef}
          visible={modalVisible}
          setVisible={setModalVisible}
          pairSet={pair}
        />
      )}
    </div>
  );
}
