import "./productPage.css";
import React, { useState, useEffect } from "react";
import { AiOutlineSwap } from "react-icons/ai";
import currencyData from "../../Currency/currency.json";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import ForexChart from "../ForexChart/forexChart";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function ProductPage() {
  let navigate = useNavigate();
  const loginUser = useSelector((state) => state.user.user.data);
  const [amountValue, setAmountValue] = useState(100);
  const [checkExch, setCheckExch] = useState(false);
  const [rate, setRate] = useState(1.08);
  const [ifLogin, setIfLogin] = useState(false);
  const [currencyFrom, setCurrencyFrom] = useState(
    currencyData.find((e) => e.code === "USD")
  );
  const [currencyTo, setCurrencyTo] = useState(
    currencyData.find((e) => e.code === "CAD")
  );
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.code} src={option.flag} className="flagImg" />
          <div>{option.code}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  useEffect(() => {
    setIfLogin(!localStorage.getItem("user") ? true : false);
  }, []);
  const valAmountEvent = (e) => {
    setAmountValue(e.value);
    setCheckExch(false);
  };

  const setFromCurrency = (e) => {
    setCheckExch(false);
    setCurrencyFrom(e.value);
  };

  const setTourrency = (e) => {
    setCheckExch(false);
    setCurrencyTo(e.value);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img className="flagImg" src={option.flag} />
        <div>{option.code}</div>
      </div>
    );
  };
  const switchCurrency = () => {
    const tempVal = { ...currencyTo };
    setCurrencyTo({ ...currencyFrom });
    setCurrencyFrom(tempVal);
    if (rate !== 0 && checkExch && amountValue) {
    }
  };
  const postOrder = () => {
    navigate("/forexsetup");
  };
  return (
    <>
      <br />
      <br />
      <br />
      <Card className="wrapCard">
        <div className="pageWrapProduct">
          <div class="inputContainerExchange">
            <div class="item">
              <div>
                <p>From&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <Dropdown
                  value={currencyFrom}
                  onChange={(e) => setFromCurrency(e)}
                  options={currencyData}
                  optionLabel="name"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                />
              </div>
            </div>
            <div class="item iconWrap">
              <AiOutlineSwap id="swapIcon" onClick={() => switchCurrency()} />
            </div>
            <div class="item">
              <div>
                <p>
                  To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <Dropdown
                  value={currencyTo}
                  onChange={(e) => setTourrency(e)}
                  options={currencyData}
                  optionLabel="name"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                />
              </div>
            </div>
          </div>
          <br />
          {
            <div id="forexChart">
              {" "}
              <ForexChart pair={currencyFrom.code + currencyTo.code} />
            </div>
          }
          <br />
          <br />
          <div class="inputContainer">
            <div class="item">
              {loginUser ? (
                <Button
                  disabled={!amountValue}
                  label="Sell Currency"
                  onClick={() => {
                    postOrder();
                  }}
                />
              ) : (
                <Button
                  disabled={true}
                  label="Login Before Sell"
                  onClick={() => {
                    postOrder();
                  }}
                />
              )}
              <Button
                disabled={!amountValue}
                label="Buy Currency"
                onClick={() => {
                  navigate("/buypage");
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
